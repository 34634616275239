<template>
  <b-card no-body>
    <b-card-header class="pb-50" />
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>الجنس</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="gender"
            :options="genderOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:gender', val)"
          />
        </b-col>
        <b-col
          v-if="role=='information_manager'
            || role=='Managers'"
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >

          <label> الخدمة</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="mainService"

            :reduce="(val) => val.value"
            :options="optionMainService"
            @input="(val) => $emit('update:mainService', val)"
          />

        </b-col>
      
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> المنطقة</label>
          <v-select
            :value="city"
            :options="CityOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:city', val) && getOCitVillage(val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> الناحية</label>
          <v-select
            :value="village"
            :options="VillageOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:village', val) && getOCovTown(val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> القرية</label>
          <v-select
            :value="town"
            :options="TownOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:town', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> طبيعة العائلة</label>
          <v-select
            :value="familyStatus"
            :options="FamilyOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:familyStatus', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label> نقاط الضعف</label>
          <treeselect
            :multiple="false"
            :options="optionsWeak"
            :value="weakness"
            @input="(val) => $emit('update:weakness', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BRow,
    Treeselect,
    BFormInput,
    flatPickr,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
  },

  props: {
    gender: {
      type: [Number, ''],
      default: '',
    },
    familyStatus: {
      type: [Number, null],
      default: null,
    },

    city: {
      type: [Number, null],
      default: null,
    },
    weakness: {
      type: [Number, null],
      default: null,
    },
    village: {
      type: [Number, null],
      default: null,
    },

    town: {
      type: [Number, null],
      default: null,
    },

    planFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },

    serviceFilter: {
      type: [Number, null],
      default: null,
    },
    mainService: {
      type: [Number, null],
      default: null,
    },

    genderOptions: {
      type: Array,
      required: true,
    },

    statusOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      serviceOptions: [],
      FamilyOptions: [],
      CityOptions: [],
      TownOptions: [],
      VillageOptions: [],
      optionsWeak: [],
      optionMainService: [],
      // mainService:'',

      role: '',
    }
  },
  watch: {
    mainService() {
      this.serviceOptions = []
      this.serviceFilter = null
      const url = `/api/v1/main_services/${this.mainService}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.serviceOptions.push({ label: el.name, value: el.id })
        })
      })
    },
  },

  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.roles[0].name

    const serviceId = localStorage.getItem('roleService')
    if (serviceId) {
      const url = `/api/v1/main_services/${serviceId}/proposal_services`
      this.$http.get(url).then(res => {
      // console.log(res);
        const subServices = res.data.data
        subServices.forEach(el => {
          this.serviceOptions.push({ label: el.name, value: el.id })
        })
      })
    }
    this.getOCovCit()
    this.getFamilyStatus()
    this.getWeak()
    this.getServiceType()
  },

  methods: {
    getWeak() {
      this.optionsWeak = []
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const subweak = res.data.data.sub_weaknesses
        const Weak = res.data.data.weaknesses

        Weak.forEach(el => {
          const obj = {}
          obj.children = []
          obj.label = el.name
          obj.id = el.id

          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id })
            }
          })
          this.optionsWeak.push(obj)
        })
      })
    },
    async getOCovCit() {
      this.CityOptions = []
      const url = '/api/v1/governorates/8/cities'
      await this.$http.get(url).then(response => {
        const Cities = response.data.data

        Cities.forEach(el => {
          this.CityOptions.push({ label: el.name, value: el.id })
        })
      })
    },

    async getOCovTown(val) {
      this.TownOptions = []

      const url = `/api/v1/towns/${val}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.TownOptions.push({ label: el.name, value: el.id })
        })
      })
    },
    async getOCitVillage(id) {
      this.VillageOptions = []
      const url = `/api/v1/cities/${id}/towns`
      await this.$http.get(url).then(response => {
        const village = response.data.data
        // console.log(village);
        village.forEach(el => {
          this.VillageOptions.push({ label: el.name, value: el.id })
        })
      })
    },
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(res.data.data.main_services)
        proposal_services.forEach(el => {
          this.optionMainService.push({ label: el.name, value: el.id })
        })
      })
    },
    getFamilyStatus() {
      this.$http.get('/api/v1/family_statuses').then(res => {
        const FamilyOptions = res.data.data

        FamilyOptions.forEach(el => {
          this.FamilyOptions.push({ value: el.id, label: el.name })
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
